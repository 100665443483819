
.tit-wrap--top-line{
    text-align: center;
    ::v-deep{
        .v-divider{
            max-width: 32px;
            margin: 0 auto;
        }
    }
}
